import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x-white.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useMutation } from '@tanstack/react-query';
import { requestVerificationEmailFn } from '@/queries/auth';
import toast from 'react-hot-toast';
import i18next from 'i18n';
import Button from './Button';
import THDialogWrapper from './THDialogWrapper';

const VerifyEmailPopup = ({ show, onHide }: { show: boolean | undefined; onHide: () => void }) => {
    const requestVerifyMutation = useMutation({
        mutationFn: requestVerificationEmailFn,
        onSuccess: () => {
            toast.success(i18next.t('success-notification.verification-email-sent'));
            onHide();
        },
        onError: () => {
            toast.error(i18next.t('error-notification.unable-send-verification-email'));
        },
    });

    return (
        <Transition.Root show={show} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-10"
                onClose={() => {
                    onHide();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity " />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-row justify-between items-center transform overflow-hidden rounded-[24px] bg-white text-left shadow-xl transition-all w-full max-w-[500px] h-auto mx-[50px] md:mx-0">
                                <button className="cursor-pointer" onClick={() => onHide()}><XIcon className="absolute right-[20px] top-[20px] [&>path]:stroke-main" /></button>
                                <div className="w-full">
                                    <div className="p-[20px] md:p-[60px] pt-[60px] max-h-[700px] overflow-y-auto">
                                        <AnimatePresence mode="wait" initial>
                                            <motion.div exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                                <div className="text-center space-y-[24px]">
                                                    <div>
                                                        <h3 className="font-heading text-[48px] leading-[48px]">{i18next.t('common:alert-text.verify-email.title')}</h3>
                                                    </div>
                                                    <div className="font-secondary flex justify-center ">
                                                        <div className="space-y-[24px] w-[280px]">
                                                            <div>
                                                                {i18next.t('common:alert-text.verify-email.subtitle')}
                                                            </div>
                                                            <Button
                                                                fullWidth
                                                                onClick={() => {
                                                                    requestVerifyMutation.mutate();
                                                                }}
                                                                loading={requestVerifyMutation.isLoading}
                                                                disabled={requestVerifyMutation.isLoading}>
                                                                {i18next.t('common:alert-text.verify-email.button-text')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        </AnimatePresence>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default VerifyEmailPopup;
