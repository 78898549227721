import i18next from 'i18n';
import * as Yup from 'yup';

export const PasswordValidator = Yup.string()
    .required(i18next.t('error-text.required'))
    .min(6, i18next.t('error-text.password.password-min-length'))
    .matches(/[0-9]/, i18next.t('error-text.password.password-missing-number'))
    .matches(/[a-z]/, i18next.t('error-text.password.password-missing-lowercase'))
    .matches(/[A-Z]/, i18next.t('error-text.password.password-missing-uppercase'))
    .matches(/[^\w]/, i18next.t('error-text.password.password-missing-symbol'));
