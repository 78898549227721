import { Constants } from '@/helpers/Constants';
import i18next from 'i18n';

export type IMenuItem = {
    title: string;
    href: string;
    icon?: React.ReactNode;
};

export type FooterMenuColumn = {
    title: string;
    items: IMenuItem[];
};

export type FooterMenu = {
    columnOne: FooterMenuColumn;
    columnTwo: FooterMenuColumn;
    columnThree: FooterMenuColumn;
};

export type ISiteSettingsMenu = {
    items: IMenuItem[];
};

export type UserSettingsMenu = {
    items: ISiteSettingsUserSettingsMenuItem[];
};

export type ISiteSettingsDefaultSeo = {
    title: string;
    description: string;
};

export type ISiteSettingsUserSettingsMenuItem = {
    title: string;
    href: string;
};

export type ISiteSettings = {
    userMenu: ISiteSettingsMenu;
    userSettings: UserSettingsMenu;
};

const useSiteSettings = () => {
    const siteSettings: ISiteSettings = {
        userMenu: {
            items: [
                {
                    title: i18next.t('header:navigation.account'),
                    href: Constants.Links.LINK_ACCOUNT,
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                            />
                        </svg>
                    ),
                },
                {
                    title: i18next.t('header:navigation.current-bids'),
                    href: Constants.Links.LINK_ITEMS_ACTIVE_BIDS,
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                            />
                        </svg>
                    ),
                },
                {
                    title: i18next.t('header:navigation.favorited'),
                    href: Constants.Links.LINK_ITEMS_LIKED,
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                            />
                        </svg>
                    ),
                },
                {
                    title: i18next.t('header:navigation.owned-items'),
                    href: Constants.Links.LINK_ITEMS_OWNED,
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                            />
                        </svg>
                    ),
                },
                {
                    title: i18next.t('header:navigation.settings'),
                    href: Constants.Links.LINK_SETTINGS,
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    ),
                }
            ],
        },
        userSettings: {
            items: [

                {
                    title: i18next.t('settings:active-bids.title'),
                    href: Constants.Links.LINK_ITEMS_ACTIVE_BIDS,
                },
                {
                    title: i18next.t('user-tabs.owned-items'),
                    href: Constants.Links.LINK_ITEMS_OWNED
                },
                {
                    title: i18next.t('user-tabs.offers'),
                    href: Constants.Links.LINK_OFFERS
                },
                {
                    title: i18next.t('user-tabs.in-transaction'),
                    href: Constants.Links.LINK_ITEMS_IN_TRANSACTION
                },
                {
                    title: i18next.t('settings:bids-history.title'),
                    href: Constants.Links.LINK_ITEMS_BIDS_HISTORY,
                },
                {
                    title: i18next.t('user-tabs.favorites'),
                    href: Constants.Links.LINK_ITEMS_LIKED
                },
                /** {
                    title: i18next.t('user-tabs.recently-viewed'),
                    href: Constants.Links.LINK_RECENTLY_VIEWED
                },* */
                {
                    title: i18next.t('settings:settings-tab.profile.tab-title'),
                    href: Constants.Links.LINK_SETTINGS_PROFILE_EDIT,
                },
                // =-==-=-=-=-=-=-==--=-=-=-=-=-=-==--=-=-=-=-=
                {
                    title: i18next.t('user-tabs.payment-methods'),
                    href: Constants.Links.LINK_SETTINGS_PAYMENT_METHOD,
                },
                {
                    title: i18next.t('settings:settings-tab.invoices.tab-title'),
                    href: Constants.Links.LINK_SETTINGS_INVOICES,
                },
                {
                    title: i18next.t('settings:settings-tab.credit-limit.tab-title'),
                    href: Constants.Links.LINK_SETTINGS_CREDIT_LIMIT,
                },
                {
                    title: i18next.t('user-tabs.security'),
                    href: Constants.Links.LINK_SETTINGS_SECURITY,
                },

            ]
        }
    };

    return {
        userMenu: siteSettings.userMenu,
        userSettings: siteSettings.userSettings
    };
};

export default useSiteSettings;
