import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XIcon from 'public/assets/icons/x.svg';
import { Item } from '@/api';
import SmallLogo from '@/components/general/LogoSmall';
import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import i18next from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { getAllOffersFn } from '@/queries/offer';
import useAuth from '@/contexts/AuthContext';
import AllOffersView from '@/components/ecommerce/product/ProductBid/AllOffersView';
import Button from '../../../general/Button';
import THDialogWrapper from '../../../general/THDialogWrapper';

type ProductOffersModalProps = {
    onHide: () => void;
    show: boolean;
    product: Item | undefined;
};

const ProductOffersHistoryModal = (props: ProductOffersModalProps) => {
    const { onHide, product, show } = props;
    const { user } = useAuth();

    const { data: offersFromReq } = useQuery({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getAllOffersFn(product!.id, true),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryKey: ['getAllOffersFn', product!.id],
        enabled: show && !!(product?.owner_id && product.owner_id === user?.id)
    });

    return (
        <Transition.Root show={show} as={Fragment}>
            <THDialogWrapper
                as="div"
                className="relative z-1000"
                onClose={() => {
                    onHide();
                }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-sm bg-black/20 transition-opacity z-[100]" />
                </Transition.Child>
                <div className="fixed z-[100] overflow-y-auto right-[0px] lg:right-[40px] lg:top-[40px] top-[0px] bottom-[0px] lg:bottom-[40px] left-[0px] lg:left-[50%]">
                    <div className="flex min-h-full items-end justify-end p-4 sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative  transform  rounded-[8px] bg-white shadow-xl transition-all w-full max-w-[100vw] lg:max-w-[40vw] lg:h-[90vh] h-[100vh] ">
                                <>
                                    <button
                                        className="cursor-pointer"
                                        onClick={() => {
                                            onHide();
                                        }}><XIcon className="absolute right-[15px] top-[26px] scale-[0.8]" />
                                    </button>
                                    <div className="text-center border-b-[1px] border-grey13">
                                        <h3 className="text-[20px] font-[500] pb-[22px]">{i18next.t('common:offers.item-offers')}</h3>
                                    </div>
                                    <div className="space-y-[20px] p-[40px] flex flex-col justify-between h-[93%]">
                                        <div className="gap-[32px] flex flex-col h-[90%]">
                                            <div>
                                                <span className="text-[24px] font-[500] block">{i18next.t('common:offers.offer-on-your-item-history')}</span>
                                            </div>

                                            <div className="flex flex-row items-center gap-[16px]">
                                                {(!product?.images || !product?.images[0].url) ?
                                                    <SmallLogo className="cursor-pointer lg-tablet:h-[80px] h-[80px] w-[80] object-cover lg-tablet:w-[80px] lg-tablet:max-h-[80px] lg-tablet:min-w-[80px] block p-12 [&>path]:fill-blue2" /> :
                                                    <ResponsiveImage src={product?.images[0].url} imageWidth={80} width={80} height={80} className="cursor-pointer lg-tablet:h-[80px] h-[120px] object-cover lg-tablet:w-[80px] lg-tablet:max-h-[80px] w-full lg-tablet:min-w-[80px] block rounded-[8px]" />}
                                                <div>
                                                    <h3 className="text-[16px] font-[500]">{product?.name}</h3>
                                                    {(product?.artists.length || 0) > 0 && <span className="text-[14px] font-[500] text-grey15">{product?.artists[0].name}</span>}
                                                </div>
                                            </div>

                                            <AllOffersView offers={offersFromReq || []} />

                                        </div>
                                        <Button onClick={onHide}>{i18next.t('common:offers.close')}</Button>
                                    </div>
                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </THDialogWrapper>
        </Transition.Root>
    );
};

export default ProductOffersHistoryModal;
